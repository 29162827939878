import { graphql, useStaticQuery } from 'gatsby';

/**
 * Queries and returns background for instructions section on home page
 * @returns {Object} - Background image of instruction section
 */
export const useInstructionBackground = () => {
    const data = useStaticQuery(graphql`
        query {
            background: file(
                relativePath: { eq: "instructions/background.png" }
            ) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);
    return data;
};
