import React, { useState } from 'react';
import { PhoneBox, SectionTemplate } from '../../components';
import styled from 'styled-components';
import { transition, media } from '../../utils/mixins';
import {
    useInstructionBackground,
    useInstructionPhoneOutline,
} from '../../queries/instructions';
import Data from '../../data/instructions.js';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Heading, Paragraph } from '@cheapreats/react-ui';

const SECTION_INCREMENT_SIZE = 1;
const INITIAL_SECTION_INDEX = 0;

export const Instructions = ({ direction }) => {
    const backgroundImage = useInstructionBackground();
    const outlineImage = useInstructionPhoneOutline();

    const numSections = Data.length;
    const [section, setSection] = useState(INITIAL_SECTION_INDEX);
    const incrementSection = () => {
        if (section + SECTION_INCREMENT_SIZE < numSections) {
            setSection(section + SECTION_INCREMENT_SIZE);
        } else {
            setSection(INITIAL_SECTION_INDEX);
        }
    };
    const selectSection = (event, section) => {
        event.stopPropagation();
        event.cancelBubble = true;
        setSection(section);
    };

    return (
        <StyledBackground
            fluid={backgroundImage.background.childImageSharp.fluid}
            backgroundColor="black"
        >
            <SectionTemplate
                headingText="No More Waiting"
                headingColor="background"
                imageContent={
                    <ImageContainer onClick={incrementSection}>
                        {Data.map((data, index) => (
                            <PhoneContainer
                                key={index}
                                active={section === index}
                                direction={direction}
                            >
                                <PhoneBox
                                    picture={data.picture}
                                    padding="200% 0 0 0"
                                    border
                                />
                            </PhoneContainer>
                        ))}
                        <Img
                            style={{
                                zIndex: -1,
                                position: 'absolute',
                                width: '100%',
                                bottom: 0,
                                transform:
                                    direction === 'left-to-right'
                                        ? 'scaleX(1)'
                                        : 'scaleX(-1)',
                            }}
                            fluid={outlineImage.outline.childImageSharp.fluid}
                        />
                    </ImageContainer>
                }
                textComponent={
                    <ItemContainer onClick={incrementSection}>
                        {Data.map((data, index) => (
                            <Item
                                active={section === index}
                                key={index}
                                onClick={(event) => selectSection(event, index)}
                            >
                                <Heading type="h5" color="white">
                                    {'Step ' + (index + 1)}
                                </Heading>
                                <Heading type="h3" color="white">
                                    {data.title}
                                </Heading>
                                <Paragraph color="white">
                                    {data.description}
                                </Paragraph>
                            </Item>
                        ))}
                    </ItemContainer>
                }
                backgroundColor="transparent"
            />
        </StyledBackground>
    );
};

const StyledBackground = styled(BackgroundImage)`
    width: 100%;
    background-position: bottom center;
    background-repeat: repeat-y;
    background-size: cover;
`;

const Container = styled.div`
    padding: 4rem;
    display: flex;
    flex-direction: ${(props) =>
            props.direction === 'left-to-right' ? 'row;' : 'row-reverse;'}
        ${media.tablet`
       flex-direction: column;
    `};
`;

const ItemContainer = styled.div`
    margin-top: 0.5rem;
    & > * {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const Item = styled.div`
    &:hover {
        cursor: pointer;
    }
    opacity: ${(props) => (props.active ? 1 : 0.4)};
    ${transition(['opacity'])}
`;

const ImageContainer = styled.div`
    padding-bottom: 130%;
    position: relative;
    overflow-x: hidden;
`;

const PhoneContainer = styled.div`
    width: 60%;
    bottom: 0;
    position: absolute;
    left: ${(props) => (props.direction === 'left-to-right' ? '25%' : '15%')};
    opacity: ${(props) => (props.active ? 1 : 0)};
    transform: ${(props) =>
        props.active ? 'translate(0)' : 'translate(200px)'};
    ${transition(['opacity', 'transform'])}
`;
