import React from 'react';
import {
    SectionTemplate,
    Stack,
    Text,
    Button,
    Card,
    Input as _Input,
} from '../../components';
import { FaPaperPlane } from 'react-icons/fa';
import styled from 'styled-components';
import { transition, darken } from '../../utils/mixins';

export const Mailing = () => {
    const submit = async (event) => {
        event.preventDefault();
    };

    return (
        <SectionTemplate backgroundColor="secondaryBackground">
            <Content
                as={Card}
                background="primary"
                direction="column"
                spacing="container"
                padding="section"
            >
                <Stack direction="column">
                    <Text as="h1" size="heading" color="white" align="center">
                        The future is here
                    </Text>
                    <Text as="p" color="white" align="center">
                        Sign-up for our newsletter to know when CheaprEats
                        launches on your campus or restaurants nearby.
                    </Text>
                </Stack>
                <Stack as="form" direction="column" onSubmit={submit}>
                    <Input
                        placeholder="Enter your email address"
                        borderColor="background"
                        name="email"
                        type="email"
                    />
                    <Stack justify="center">
                        <Button
                            background="primary"
                            color="background"
                            leftIcon={FaPaperPlane}
                        >
                            Subscribe now
                        </Button>
                    </Stack>
                </Stack>
            </Content>
        </SectionTemplate>
    );
};

const Content = styled(Stack)`
    max-width: 48rem;
    margin: auto;
    margin-bottom: 100px;
`;

const Input = styled(_Input)`
    ${transition(['border-color', 'background-color'])}
    ${({ theme }) => `
        &:focus {
            border-color: ${darken(theme.colors.background)};
            background-color: ${darken(theme.colors.background)};
        }
    `}
`;
