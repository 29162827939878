import { graphql, useStaticQuery } from 'gatsby';

/**
 * Queries and returns grass outline and shadow for phone images on the instructions
 * @returns {Object} - outline image for phone
 */
export const useInstructionPhoneOutline = () => {
    const data = useStaticQuery(graphql`
        query {
            outline: file(relativePath: { eq: "instructions/grass.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return data;
};
