import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { SectionTemplate, Stack, Text } from '../../components';
import { transition, media } from '../../utils/mixins';
import { Heading, Button, Mixins } from '@cheapreats/react-ui';

/* eslint-disable jsx-a11y/accessible-emoji */
/* Since we have already addressed it, but it complains anyways */
export const Features = () => {
    const headerComponent = (
        <>
            <Heading type="h2" color="black">
                The Best Food App on Campus
            </Heading>
            <Heading type="h5" color="text">
                Tight schedule? Place your order on the go. Directly pick it up
                at the store and skip the line! Invite friends at your
                university campus to use CheaprEats and receive great discounts
                together. We make it faster, cheaper and more convenient than
                ever to order and eat food on campus.
            </Heading>
        </>
    );

    const navigateToVendor = () => navigate('/vendor');

    return (
        <SectionTemplate
            header={headerComponent}
            footer={
                <Button
                    color="secondaryBackground"
                    margin="auto"
                    padding="15px 30px"
                    onClick={navigateToVendor}
                >
                    <ButtonRow>
                        <Icon as={AiOutlineInfoCircle} />
                        Learn More
                    </ButtonRow>
                </Button>
            }
            backgroundColor="secondaryBackground"
        >
            <Items>
                <Item as="li" direction="column">
                    <Text as="emoji" aria-label="noodle" size="heading">
                        🍲
                    </Text>
                    <Text as="h3" size="large">
                        Order meals on the go
                    </Text>
                    <Text>
                        No more waiting. Order your food on the CheaprEats app,
                        pickup when ready and skip long lines.
                    </Text>
                </Item>
                <Item as="li" direction="column">
                    <Text as="emoji" aria-label="party popper" size="heading">
                        🎉
                    </Text>
                    <Text as="h3" size="large">
                        Earn and Share Discounts
                    </Text>
                    <Text>
                        Earn loyalty points redeemable for discounts on future
                        meals. Share these points with friends!
                    </Text>
                </Item>
                <Item as="li" direction="column">
                    <Text as="emoji" aria-label="heart" size="heading">
                        💖
                    </Text>
                    <Text as="h3" size="large">
                        Your Favourite Places and More
                    </Text>
                    <Text>
                        Order from your favourite restaurant or explore new ones
                        from a specially curated list designed to help you find
                        your next favourite one.
                    </Text>
                </Item>
                <Item as="li" direction="column">
                    <Text as="emoji" aria-label="credit card" size="heading">
                        💳
                    </Text>
                    <Text as="h3" size="large">
                        Payment Flexibility
                    </Text>
                    <Text>
                        MasterCard, Visa, AMEX, Apple Pay, Android Pay - We've
                        got you covered! You can even pay at vendor location by
                        cash.
                    </Text>
                </Item>
            </Items>
        </SectionTemplate>
    );
};

const ButtonRow = styled.div`
    ${Mixins.flex('row', 'center')}
    ${({ theme }) => `
        color: ${theme.colors.black};
    `}
`;
const Icon = styled.svg`
    width: 20px;
    height: 20px;
    margin-right: 5px;
`;

const Items = styled.ul`
    list-style-type: none;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    ${({ theme }) => `
        grid-gap: ${theme.spacing.container};
        padding: ${theme.spacing.section} 0;
    `}
    margin: 0;

    ${media.tablet`
        grid-template-columns: 1fr;
    `}
`;

const Item = styled(Stack)`
    ${transition(['box-shadow'])}
    ${({ theme }) => `
        background-color: ${theme.colors.background};
        border-radius: ${theme.dimensions.radius};
        padding: ${theme.spacing.container};
        &:hover {
            box-shadow: ${theme.shadow};
        }
    `}
`;
