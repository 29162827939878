import phone1 from '../images/instructions/phones/1.png';
import phone2 from '../images/instructions/phones/2.png';
import phone3 from '../images/instructions/phones/3.png';

const Data = [
    {
        title: 'Order',
        description:
            'Browse through our wide range of vendors and dishes to find the ones that satisfy any cravings',
        picture: phone1,
    },
    {
        title: 'Customize',
        description:
            'Available changes, extras of anything else, can be applied  simply by clicking the pencil icon to make any dish perfect for you.',
        picture: phone2,
    },
    {
        title: 'Pay',
        description:
            'Once all dishes are added into the basket, finish off the order by continuing to payment and confirming!',
        picture: phone3,
    },
];

export default Data;
