import React from 'react';
import { Landing, Features, Mailing, Instructions } from '../sections/home';
import { Layout } from '../components';

const Home = () => (
    <Layout title="Home">
        <Landing />
        <Features />
        <Mailing />
        <Instructions direction="left-to-right" />
    </Layout>
);

export default Home;
