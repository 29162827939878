import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { SectionTemplate, Stack } from '../../components';
import mask from '../../images/blob-shape.svg';
import { media } from '../../utils/mixins';
import { Mixins } from '@cheapreats/react-ui';

export const Landing = () => {
    const { dinning, appstore, googleplay } = useStaticQuery(query);

    return (
        <SectionTemplate
            headingText="Order Now,"
            headingProps={{ type: 'h1', size: '3.5rem', color: 'black' }}
            subHeadingText="Pickup Later."
            subHeadingProps={{ type: 'h1', size: '3rem', color: 'black' }}
            paragraphText={`Skip the line, and get discounts with friends!`}
            textComponent={
                <Buttons spacing="container">
                    <a href="https://apps.apple.com/us/app/cheapreats/id1494030610">
                        <Download fluid={appstore.childImageSharp.fluid} />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.cheapreats.customer">
                        <Download fluid={googleplay.childImageSharp.fluid} />
                    </a>
                </Buttons>
            }
            imageContent={<Image fluid={dinning.childImageSharp.fluid} />}
        />
    );
};

const query = graphql`
    {
        dinning: file(relativePath: { eq: "dinning.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        appstore: file(relativePath: { eq: "appstore.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        googleplay: file(relativePath: { eq: "googleplay.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const Buttons = styled(Stack)`
    padding-top: ${({ theme }) => theme.spacing.component};
    ${media.tablet`
        justify-content: center;
    `}
    ${Mixins.media('phone', Mixins.flex('column'))}
`;

const Download = styled(Img)`
    width: 9rem;
    height: 3rem;
`;

const Image = styled(Img)`
    mask: url(${mask});
    mask-size: cover;
    max-width: 26rem;
    max-height: 26rem;
    width: 100%;
    height: auto;
    ${media.tablet`
        max-width: 20rem;
        max-height: 20rem;
    `}
`;
